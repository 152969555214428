import React, { ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import home from "@teliads/components/icons/commonjs/assets/home";

import styles from "./b2x-breadcrumbs.module.scss";

const rootClassName = "b2x-breadcrumbs";

type Props = {
  darkBackground?: boolean;
  ariaLabel?: string;
  dataTestid: string;
  children: ReactNode;
};

export const B2xBreadcrumbs = ({
  darkBackground = false,
  ariaLabel,
  dataTestid,
  children,
}: Props) => {
  useEffect(() => {
    obsoleteWarning(rootClassName, "components-breadcrumbs--docs");
  }, []);

  const backgroundClassName = darkBackground ? styles["breadcrumbs--dark-background"] : "";
  const iconElement = (
    <li className={`${styles["breadcrumbs__home-icon"]}`}>
      <TeliaIcon dataTestid="breadcrumbs-icon" svg={home.svg} size="sm" aria-label="Home icon" />
    </li>
  );

  return (
    <nav
      className={`${styles["breadcrumbs"]} ${backgroundClassName}`}
      data-testid={dataTestid}
      aria-label={ariaLabel}
    >
      <ol className={`${styles["breadcrumbs__list"]}`}>
        {iconElement}
        {children}
      </ol>
    </nav>
  );
};
